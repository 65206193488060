export function findDataCustom(array, id) {
  for (var i = 0; i < array.length; i++) {
    if (array[i].brandId === id) {
      return array[i];
    }
  }
  return null;
}

import fontsList from "utils/fontsList";

export function findFont(name) {
  return name ? name.replace(/\s/g, "-") : name;

  // return font.data.items.find((item) => item.family === name);

  // for (var i = 0; i < fontsList.length; i++) {
  //   if (fontsList[i].name === name) {
  //     return fontsList[i].classname;
  //   }
  // }
}
