import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function fetchMe(token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/me`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response.data;
    return data.user;
  } catch (error) {
    return error;
  }
}
