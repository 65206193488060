const editDisplay = [
  {
    id: 1,
    title: "prix de la carte",
    visible: true,
  },
  {
    id: 2,
    title: "photos",
    visible: true,
  },
];

export default editDisplay;
