import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./models/userInfo";
import brandReducer from "./models/restaurantInfo";
import customReducer from "./models/customInfo";

export default configureStore({
  reducer: {
    user: userReducer,
    brand: brandReducer,
    custom: customReducer,
  },
});
