const editFont = [
  {
    id: 1,
    title: "catégories",
    custom: {
      fontFamily: "poppins",
      fontSize: 20,
      letterSpacing: 0,
      bold: true,
      italic: false,
    },
  },
  {
    id: 2,
    title: "formules",
    custom: {
      fontFamily: "poppins",
      fontSize: 38,
      letterSpacing: 0,
      bold: false,
      italic: false,
    },
  },
  {
    id: 3,
    title: "plats",
    custom: {
      fontFamily: "poppins",
      fontSize: 16,
      letterSpacing: 0,
      bold: false,
      italic: false,
    },
  },
  {
    id: 4,
    title: "ingrédients",
    custom: {
      fontFamily: "poppins",
      fontSize: 14,
      letterSpacing: 0,
      bold: false,
      italic: false,
    },
  },
  {
    id: 5,
    title: "prix",
    custom: {
      fontFamily: "poppins",
      fontSize: 16,
      letterSpacing: 0,
      bold: false,
      italic: false,
    },
  },
];

export default editFont;
