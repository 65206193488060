export const getPositionWithPercent = (percent) => {
  if (!percent) {
    return 0;
  }
  const height = document.documentElement.scrollHeight;
  const position = (height * percent) / 100;
  return position;
};

export const getPositionInPercent = () => {
  const scrollPercent = (
    (window.scrollY / (document.body.scrollHeight - window.innerHeight)) *
    100
  ).toFixed(2);
  return scrollPercent;
};
