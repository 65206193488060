import joinClasses from "helpers/joinClasses";

export function SubParagraph({ className = "", children }) {
  return (
    <h1
      className={joinClasses(
        className,
        "font-poppins  font-light uppercase text-black"
      )}
      style={{ fontSize: "12px" }}
    >
      {children}
    </h1>
  );
}
