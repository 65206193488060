const editColor = [
  {
    id: 1,
    title: "fond",
    custom: {
      color: "#ffffff",
    },
  },
  {
    id: 2,
    title: "catégories",
    custom: {
      color: "#000000",
    },
  },
  {
    id: 3,
    title: "texte",
    custom: {
      color: "#000000",
    },
  },
  {
    id: 4,
    title: "séparateurs",
    custom: {
      color: "#000000",
    },
  },
  {
    id: 5,
    title: "formules",
    custom: {
      color: "#36393F",
    },
  },
];

export default editColor;
